import MultiDatePicker, { DateObject } from "react-multi-date-picker";
import HijriCalendar from "react-date-object/calendars/arabic";
import GregorianCalendar from "react-date-object/calendars/gregorian";
import HijriArabicCalendar from "react-date-object/locales/arabic_ar";
import HijiryEnglishCalendar from "react-date-object/locales/arabic_en";
import GregorianArabicCalendar from "react-date-object/locales/gregorian_ar";
import "./DatePicker.scss";
import { useCookieContext } from "contexts";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

// Create a custom Hijri Arabic calendar with English digits
const CustomHijriArabicCalendar: any = {
  ...HijriArabicCalendar, // Extend the existing Hijri Arabic calendar
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"], // Force the digits to be in English
};
const CustomGregorianArabicCalendar: any = {
  ...GregorianArabicCalendar, // Extend the existing Hijri Arabic calendar
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"], // Force the digits to be in English
};
type DatePickerProps = {
  label?: string;
  placeholder?: string;
  value?: string | Date | DateObject;
  wrapperClassName?: string;
  error?: string;
  labelClassName?: string;
  withTimePicker?: boolean;
  onlyTimePicker?: boolean;
  onlyDatePicker?: boolean;
  hideIcon?: boolean;
  inputClassNames?: string;
  range?: boolean;
  withAsterisk?: boolean;
} & (
  | {
      range?: false;
      onChange?: (date: Date | null) => void;
    }
  | {
      range: true;
      onChange?: (date: Date[] | null) => void;
    }
);

// TODO: Icon positioning and Coloring (.icon-tabler-calendar-event)
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    label,
    placeholder,
    value,
    onChange,
    wrapperClassName,
    error,
    labelClassName,
    onlyDatePicker = true,
    onlyTimePicker,
    withTimePicker,
    hideIcon,
    withAsterisk,
    inputClassNames,
    range,
  } = props;

  const { isAr, isHijri, isRTL } = useCookieContext();

  const onLocalChange = (e: DateObject | DateObject[] | null) => {
    if (!range) {
      const date = e as DateObject | null;
      onChange?.(date?.toDate() ?? null);
    } else {
      const dates = e as DateObject[] | null;
      onChange?.(dates?.map((d) => d.toDate()) ?? null);
    }
  };

  return (
    <div className={`${wrapperClassName}`}>
      <div className={`date-picker-wrapper position-relative ${error ? "is-invalid" : ""}`}>
        {label && (
          <div className={`mb-1 ${labelClassName} position-relative`}>
          
            {typeof label === "string" ? <label className={`${withAsterisk ? "px-2" : ""}`}>{label}</label> : label}
            {withAsterisk && (
                <span
                    className={` text-danger`}
                    style={{
                      fontSize: "0.75rem",
                    }}
                >
                *
              </span>
            )}
          </div>
        )}
        <MultiDatePicker
          range={range}
          placeholder={placeholder ?? label}
          value={typeof value === "string" ? new Date(value) : value}
          inputClass={`form-control ${inputClassNames} ${error ? "is-invalid" : ""}`}
          containerClassName="w-100"
          inputMode="input"
          calendar={isHijri ? HijriCalendar : GregorianCalendar}
          onChange={onLocalChange}
          disableDayPicker={onlyTimePicker}
          format={onlyTimePicker ? "HH:mm:ss" : onlyDatePicker ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss"}
          plugins={withTimePicker || onlyTimePicker ? [<TimePicker position="bottom" />] : []}
          locale={
            isAr
              ? isHijri
                ? CustomHijriArabicCalendar
                : CustomGregorianArabicCalendar
              : isHijri
              ? HijiryEnglishCalendar
              : undefined
          }
        />
        {!hideIcon && (
          <span
            style={{ pointerEvents: "none" }}
            className={` ${
              label ? "icon-date-picker" : "icon-date-picker-without-label"
            } icon-calendar text-primary fs-4`}
          />
        )}
      </div>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default DatePicker;

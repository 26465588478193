import IconButton from "components/blocks/IconButton";
import { Link } from "react-router-dom";
import { OrderStatus } from "shared/lookups";
import { OrderUserTypes, TapsIds, tabsIds } from "..";
import { OpenFundingTaskResult } from "services/tasks/models/tasksTypes";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { useTranslation } from "react-i18next";
import useFundingUnitActions from "./useFundingUnitActions";
import ReturnOrderToCenterModal from "../OrderTaskActionModals/ReturnOrderToCenterModal";
import useDeanOfScientificResearch from "./useDeanOfScientificResearch";
import ReturnOrderContractModal from "../OrderTaskActionModals/ReturnOrderContractModal";
import { EvaluateProgramUpdateRef } from "../Tabs/OrderInfoTab/components/Updates/useProgramOrderUpdateActions";
import ResearcherRequestActions from "./ResearcherRequestActions";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import useCloseRequestActions, { ApproveByCenterManagerSubmitHandlerRef } from "./useCloseRequestActions";
import CloseRequestModal from "../OrderTaskActionModals/CloseRequestModal";
import useGenericActions from "./useGenericActions";
import GenericActionsModal from "../OrderTaskActionModals/GenericActionsModal";
import AuthGuard from "components/blocks/AuthGuard";
import PayrollOfficerModal from "../OrderTaskActionModals/PayrollOfficerModal";
import usePayrollOfficerActions from "./usePayrollOfficerActions";

type PageActionsProps = {
  selectedTabId: string;
  order: OrderLocalItemResult | null;
  openedTask: OpenFundingTaskResult | null;
  programOrderTaskState:
    | {
        actions: string[];
        canReturn: boolean;
        orderId: number;
      }
    | undefined;
  onActionClicked: (action: "return" | "respond") => void;
  onSave: (selectedTabId: TapsIds) => void;
  userType: keyof typeof OrderUserTypes;
  programOrderId: number;
  serialGuid: string;
  programUpdateHandlersRef: React.MutableRefObject<EvaluateProgramUpdateRef | null>;
  program: ProgramByIDResponse | null;
  approveByCenterManagerSubmitHandlerRef?: React.MutableRefObject<ApproveByCenterManagerSubmitHandlerRef | null>;
  setTriggerOrderUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  withGenericActions?: boolean;
};

const PageActions = ({
  selectedTabId,
  order,
  openedTask,
  programOrderTaskState,
  onActionClicked,
  onSave,
  userType,
  programOrderId,
  serialGuid,
  programUpdateHandlersRef,
  program,
  approveByCenterManagerSubmitHandlerRef,
  withGenericActions,
  setTriggerOrderUpdate,
}: PageActionsProps) => {
  const { t } = useTranslation(["Orders", "Shared"]);

  const isFundingUnit = userType === OrderUserTypes.FundingUnit;
  const isResearcherCenterManager = userType === OrderUserTypes.ResearchCenterManager;
  const isDeanSecretary = userType === OrderUserTypes.SecretaryOfTheInitiative;
  const isDeanOfScientificResearch = userType === OrderUserTypes.DeanOfScientificResearch;

  const {
    confirmFundingUnitLoading,
    onConfirmFundingUnit,
    onReturnOrderToCentreManagement,
    returnOrderToCentreManagementLoading,
    isReturnToCenterModalOpen,
    setIsReturnToCenterModalOpen,
  } = useFundingUnitActions({ programOrderId, serialGuid });

  const {
    deanOfScientificResearchLoading,
    isReturnContractModalOpen,
    onAcceptSignContract,
    onReturnContract,
    returnContractLoading,
    setIsReturnContractModalOpen,
  } = useDeanOfScientificResearch({ programOrderId, serialGuid });

  const {
    onReturn,
    onApprove,
    onReject,
    onComplete,
    loading,
    closeRequestModalProps,
    setCloseRequestModalProps,
    userCloseActions,
  } = useCloseRequestActions({
    programOrderCloseId: openedTask?.programOrderCloseId ?? order?.closeOrder?.id ?? 0,
    serialGuid,
    userType,
    approveByCenterManagerSubmitHandlerRef,
  });

  const {
    loading: payrollOfficerActionLoading,
    payrollOfficerModalProps,
    setPayrollOfficerModalProps,
    onComplete: onApprovePayrollOfficer,
  } = usePayrollOfficerActions({
    programOrderCloseId: openedTask?.programOrderCloseId ?? order?.closeOrder?.id ?? 0,
    serialGuid,
  });

  const { confirmLoading, genericModalOpenProps, onGenericActionClicked, setGenericModalOpenProps } = useGenericActions(
    {
      serialGuid,
      orderId: order?.id ?? 0,
    },
  );

  return (
    <>
      {[tabsIds.programMinutesTab, tabsIds.speechesTab, tabsIds.additionalAttachmentsTab].includes(
        selectedTabId as TapsIds,
      ) && (
        <button
          key="save"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSave(selectedTabId as TapsIds);
          }}
          className="btn btn-primary btn-sm rounded px-3"
        >
          {t("save")}
        </button>
      )}

      {selectedTabId === tabsIds.orderInfoTab && (
        <>
          {(isResearcherCenterManager || isDeanSecretary) && programOrderTaskState && (
            <>
              {order?.isInspectMode && programOrderTaskState.actions.length > 0 && (
                <button className="btn btn-primary px-3" onClick={() => onActionClicked("respond")}>
                  {isDeanSecretary
                    ? t("sendToSecretaryOfTheInitiative")
                    : isResearcherCenterManager
                    ? t("sendToTheFundingUnit")
                    : t("send")}
                </button>
              )}
              {programOrderTaskState.canReturn && (
                <button
                  className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                  onClick={() => onActionClicked("return")}
                >
                  <i className="icon-turn-order" />
                  {t("returnOrder")}
                </button>
              )}
            </>
          )}

          {isFundingUnit && selectedTabId === tabsIds.orderInfoTab && (
            <>
              {openedTask?.actionName.includes("Finish") && (
                <button
                  disabled={returnOrderToCentreManagementLoading || confirmFundingUnitLoading}
                  className="btn btn-primary px-3"
                  onClick={() => onConfirmFundingUnit()}
                >
                  {t("approval")}
                </button>
              )}
              {openedTask?.actionName.includes("Return") && (
                <button
                  type="button"
                  disabled={returnOrderToCentreManagementLoading || confirmFundingUnitLoading}
                  className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                  onClick={() => setIsReturnToCenterModalOpen(true)}
                >
                  <i className="icon-turn-order" />
                  {t("returnOrderToCentreManagement")}
                </button>
              )}
            </>
          )}

          {isDeanOfScientificResearch && (
            <>
              {openedTask?.actionName.includes("Approve") && (
                <button
                  disabled={deanOfScientificResearchLoading || returnContractLoading}
                  className="btn btn-primary px-3"
                  onClick={() => onAcceptSignContract()}
                >
                  {t("approval")}
                </button>
              )}
              {openedTask?.actionName.includes("Return") && (
                <button
                  type="button"
                  disabled={returnContractLoading || deanOfScientificResearchLoading}
                  className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                  onClick={() => setIsReturnContractModalOpen(true)}
                >
                  <i className="icon-turn-order" />
                  {t("returnOrder")}
                </button>
              )}
            </>
          )}

          {userCloseActions && (
            <>
              {openedTask?.actionName.includes("Approve") && (
                <button className="btn btn-primary px-3" onClick={() => onApprove()} disabled={loading}>
                  {t("approve", { placeholder: "" })}
                </button>
              )}
              {openedTask?.actionName.includes("Complete") && (
                <button
                  className="btn btn-primary px-3"
                  onClick={() => {
                    setCloseRequestModalProps({
                      isOpen: true,
                      actionType: "complete",
                    });
                  }}
                  disabled={loading}
                >
                  {t("ApprovalOfTheDisbursementAndAttachmentOfTheReceipt")}
                </button>
              )}
              {openedTask?.actionName.some((ele) => ele.toLowerCase().includes("return")) && (
                <button
                  className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                  onClick={() => {
                    setCloseRequestModalProps({
                      isOpen: true,
                      actionType: "return",
                    });
                  }}
                  disabled={loading}
                >
                  <i className="icon-turn-order " />
                  {userCloseActions.returnToTitleKey
                    ? t("returnTo", {
                        placeholder: t(userCloseActions.returnToTitleKey ?? ""),
                      })
                    : t("returnOrder")}
                </button>
              )}
              {openedTask?.actionName.includes("Reject") && (
                <button
                  className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                  onClick={() => {
                    setCloseRequestModalProps({
                      isOpen: true,
                      actionType: "reject",
                    });
                  }}
                  disabled={loading}
                >
                  {t("reject", { placeholder: t("request") })}
                </button>
              )}
            </>
          )}

          {withGenericActions && (
            <>
              {openedTask?.actionName.map((action) => {
                const actionParts = action.split(" ");
                const msg = actionParts[0].toLowerCase().includes("return")
                  ? t("returnOrder")
                  : t(actionParts[0].toLowerCase(), { placeholder: "" });

                return (
                  <button
                    className={`btn btn-${
                      action.toLowerCase().includes("reject")
                        ? "danger text-white"
                        : action.toLowerCase().includes("return")
                        ? "secondary"
                        : "primary"
                    } px-3`}
                    onClick={() =>
                      setGenericModalOpenProps({
                        isOpen: true,
                        msg: msg,
                        action: action,
                      })
                    }
                    disabled={loading}
                  >
                    {msg}
                  </button>
                );
              })}
            </>
          )}

          {userType === OrderUserTypes.ManagerOfTheInitiative && (
            <>
              {openedTask?.actionName.includes("Approve") && (
                <button
                  className="btn btn-primary px-3"
                  onClick={async () => {
                    await approveByCenterManagerSubmitHandlerRef?.current?.acceptProgramOrderUpdateByCenterManager({
                      actionName: "approve",
                      serialGuid: serialGuid,
                      notes: "",
                    });
                  }}
                  disabled={loading}
                >
                  {t("approve", { placeholder: "" })}
                </button>
              )}

              {openedTask?.actionName.some((ele) => ele.toLowerCase().includes("return")) && (
                <button
                  className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                  onClick={() => {
                    setGenericModalOpenProps({
                      isOpen: true,
                      msg: t("returnOrder"),
                      action: openedTask?.actionName.find((ele) => ele.toLowerCase().includes("return")) ?? "Return",
                    });
                  }}
                  disabled={loading}
                >
                  <i className="icon-turn-order " />
                  {t("returnOrder")}
                </button>
              )}
              {openedTask?.actionName.includes("Reject") && (
                <button
                  className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                  onClick={() => {
                    setGenericModalOpenProps({
                      isOpen: true,
                      msg: t("reject", { placeholder: t("request") }),
                      action: "Reject",
                    });
                  }}
                  disabled={loading}
                >
                  {t("reject", { placeholder: t("request") })}
                </button>
              )}
            </>
          )}

          {userType === OrderUserTypes.PayrollOfficer && (
            <>
              {openedTask?.actionName.includes("Complete") && (
                <button
                  className="btn btn-primary px-3"
                  onClick={() => {
                    setPayrollOfficerModalProps({
                      isOpen: true,
                    });
                  }}
                  disabled={loading}
                >
                  {t("ApprovalOfTheDisbursementAndAttachmentOfTheReceipt")}
                </button>
              )}
              {openedTask?.actionName.some((ele) => ele.toLowerCase().includes("return")) && (
                <button
                  className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                  onClick={() => {
                    setGenericModalOpenProps({
                      isOpen: true,
                      msg: t("returnOrder"),
                      action: "Return",
                    });
                  }}
                  disabled={loading}
                >
                  <i className="icon-turn-order " />
                  {t("returnOrder")}
                </button>
              )}
            </>
          )}

          <ResearcherRequestActions
            openedTask={openedTask}
            programUpdateHandlersRef={programUpdateHandlersRef}
            serialGuid={serialGuid}
            userType={userType}
            order={order}
            program={program}
            setTriggerOrderUpdate={setTriggerOrderUpdate}
          />

          {order?.orderStatusId === OrderStatus.Draft && (
            <>
              <AuthGuard requiredRoles={["FundingOrdersWrite"]}>
                <Link to={`/home/orders/edit/${openedTask?.orderId || order.id}`}>
                  <IconButton
                    key="edit"
                    icon="icon-edit"
                    size="md"
                    className="btn action-btn btn-sm rounded p-2"
                    variant="light"
                    innerIconColor="secondary"
                  />
                </Link>
              </AuthGuard>
              <AuthGuard requiredRoles={["FundingOrdersDelete"]}>
                <IconButton
                  key="delete"
                  icon="icon-delete"
                  size="md"
                  className="btn action-btn btn-sm rounded p-2"
                  variant="light"
                  innerIconColor="danger"
                />
              </AuthGuard>
            </>
          )}
        </>
      )}

      <ReturnOrderToCenterModal
        isOpen={isReturnToCenterModalOpen}
        onClose={() => setIsReturnToCenterModalOpen(false)}
        isSubmitting={returnOrderToCentreManagementLoading}
        onSubmit={onReturnOrderToCentreManagement}
      />

      <ReturnOrderContractModal
        isOpen={isReturnContractModalOpen}
        onClose={() => setIsReturnContractModalOpen(false)}
        isSubmitting={returnContractLoading}
        onSubmit={onReturnContract}
        actionName={openedTask?.actionName}
      />

      <GenericActionsModal
        isOpen={genericModalOpenProps.isOpen}
        onClose={() =>
          setGenericModalOpenProps({
            isOpen: false,
            msg: "",
            action: "",
          })
        }
        isSubmitting={confirmLoading}
        onSubmit={onGenericActionClicked}
        msg={genericModalOpenProps.msg}
        action={genericModalOpenProps.action}
      />

      <CloseRequestModal
        isSubmitting={loading}
        type={closeRequestModalProps.actionType}
        isOpen={closeRequestModalProps.isOpen}
        returnToTitleKey={userCloseActions?.returnToTitleKey ?? ""}
        onClose={() => setCloseRequestModalProps({ isOpen: false, actionType: closeRequestModalProps.actionType })}
        onSubmit={async (notes, receiptId) => {
          if (closeRequestModalProps.actionType === "return") {
            await onReturn(notes);
          } else if (closeRequestModalProps.actionType === "complete") {
            await onComplete(notes, receiptId!);
          } else {
            await onReject(notes);
          }
        }}
      />

      <PayrollOfficerModal
        isOpen={payrollOfficerModalProps.isOpen}
        onSubmit={onApprovePayrollOfficer}
        isSubmitting={payrollOfficerActionLoading}
        onClose={() => {
          setPayrollOfficerModalProps({
            isOpen: false,
          });
        }}
      />
    </>
  );
};
export default PageActions;

import PageWithTabs from "components/PageWithTabs";
import { useTranslation } from "react-i18next";
import Switch from "components/blocks/Switch";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useCookieContext } from "contexts";
import AboutProgramTab from "./tabs/AboutProgramTab";
import OrdersTab from "./tabs/OrdersTap";
import ApplicantsTab from "./tabs/ApplicantsTab";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { programUserTypes } from "../ProgramsGridView";
import useViewProgram from "./useViewProgram";
import AdminActions from "./ActionButtons/AdminActions";
import FundingUnitActions from "./ActionButtons/FundingUnitActions";
import VicDeanActions from "./ActionButtons/VicDeanActions";
import ResearcherActions from "./ActionButtons/ResearcherActions";
import { useRef, useState } from "react";
import { AddEditProgramMinutesRef } from "./tabs/OrdersTap/ViceDeanOrdersTab/AddProgramMinutes";

type TapsIds = "about" | "orders" | "applicants";
const tabsIds: { [key in TapsIds]: TapsIds } = {
  about: "about",
  orders: "orders",
  applicants: "applicants",
};

const ViewProgram = ({ userType }: { userType: keyof typeof programUserTypes }) => {
  // Hooks
  const { isAr } = useCookieContext();
  const { t } = useTranslation("Programs");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const addEditProgramMinutesRef = useRef<AddEditProgramMinutesRef>(null);
  const notesForAcceptsRef = useRef<string>("");
  const { loading, programData, programId, orderData, serialGID, actionNames } = useViewProgram({ userType });
  const [isResearcherAcceptanceButtonDisabled, setIsResearcherAcceptanceButtonDisabled] = useState(true);

  // TODO: get the values instead of ids
  const subTitle = programData
    ? `${t("programNumber")}: ${programData?.serialNumber} • ${t("cycle")}: ${
        programData?.programCycleYearDetail.displayName
      }-${programData?.programCycleDetail.displayName}`
    : "";
  const programName = programData
    ? `${programData?.programDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName}`
    : "";

  return (
    <>
      <LoadingOverlay visible={loading} />
      <PageWithTabs
        titleImgSrc={
          programData
            ? `https://apiGateway.aaltair.net/FileManagerApi/File/Download/${programData.adsImageId}`
            : undefined
        }
        title={
          <div className="d-flex gap-2">
            {programName}
            <Switch checked onChange={() => {}} />
          </div>
        }
        subTitle={subTitle}
        defaultTabId={userType === programUserTypes.Admin ? tabsIds.about : tabsIds.orders}
        // TODO: set keep keep tabs mounted to true once grid store is keyed
        // Currently grid store is shared (2 paginated grid cannot be collocated in the same component)        // keepTabsMounted
        pageHeaders={
          !programData
            ? []
            : [
                {
                  title: t("programTypeClass"),
                  // TODO: get the values from backend
                  value: `${programData?.programTypeName} - ${programData?.classificationProgramName}`,
                },
                {
                  title: t("adsDuration"),
                  value: `${toSystemThemeDateFormat(programData.adsStartDate)} - ${toSystemThemeDateFormat(
                    programData.adsEndDate,
                  )}`,
                },
                {
                  title: t("funding"),
                  value: `${programData.fundingDurationInMonths} ${t("months")} - ${programData.fundingAmount}${t(
                    "Common:riyal",
                  )}`,
                },
                {
                  title: t("contractStart"),
                  value: `${toSystemThemeDateFormat(programData.contractStartDate)}`,
                },
                {
                  title: t("programOrders"),
                  value: programData?.numberOfOrders ?? 0,
                },
                {
                  title: t("targetedAudience"),
                  value: programData.audienceTypes
                    .map((audience) => audience.audienceTypeDetail.displayName)
                    .join(", "),
                },
              ]
        }
        actions={() => {
          return (
            <>
              {userType === programUserTypes.Admin && (
                <AdminActions programId={programId} programData={programData} isAr={isAr} />
              )}

              {userType === programUserTypes.FundingUnit && <FundingUnitActions programData={programData} />}
              {userType === programUserTypes.ViceDean && (
                <VicDeanActions addEditProgramMinutesRef={addEditProgramMinutesRef} />
              )}
              {userType === programUserTypes.Researcher && (
                <ResearcherActions
                  isResearcherAcceptanceButtonDisabled={isResearcherAcceptanceButtonDisabled}
                  orderId={orderData?.id ?? 0}
                  serialGuid={serialGID ?? ""}
                  actionNames={actionNames}
                  notesForAcceptsRef={notesForAcceptsRef}
                />
              )}
            </>
          );
        }}
        tabs={[
          {
            id: tabsIds.about,
            title: t("aboutProgram"),
            content: programData ? <AboutProgramTab program={programData} /> : null,
          },
          {
            id: tabsIds.orders,
            title: t("orders"),
            content: programData ? (
              <div className="p-4">
                <OrdersTab
                  program={programData}
                  userType={userType}
                  orderData={orderData}
                  setIsResearcherAcceptanceButtonDisabled={setIsResearcherAcceptanceButtonDisabled}
                  addEditProgramMinutesRef={addEditProgramMinutesRef}
                  actionNames={actionNames}
                  serialGuid={serialGID}
                  notesForAcceptsRef={notesForAcceptsRef}
                />
              </div>
            ) : null,
          },
          userType === programUserTypes.Admin
            ? {
                id: tabsIds.applicants,
                title: t("applicants"),
                content: programData ? <ApplicantsTab programId={programData.id} /> : null,
              }
            : null,
        ]}
      />
    </>
  );
};

export default ViewProgram;

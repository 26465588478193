import PageWithTabs from "components/PageWithTabs";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import OrderFormDetails from "./OrderFormDetails";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import IconButton from "components/blocks/IconButton";
import { useNavigate } from "react-router-dom";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useOrderForm from "./useOrderForm";
import useDeanOfScientificResearchActions from "./useDeanOfScientificResearchActions";
import ReturnOrRejectUpdateOrderModal from "./ReturnOrRejectUpdateOrderModal";

export const OrderFormTypes = {
  EditOrder: "EditOrder",
  ReturnedOrder: "ReturnedOrder",
  AddOrder: "AddOrder",
  EditOrderByDeanOfScientificResearch: "EditOrderByDeanOfScientificResearch",
} as const;

export type AddEditFormRef = { submitForm: (isDraft: boolean, orderFormType: keyof typeof OrderFormTypes) => void };

const OrderForm = ({ orderFormType }: { orderFormType: keyof typeof OrderFormTypes }) => {
  const { setBreadcrumbs } = useBreadcrumb();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["Orders", "Common"]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const formRef = useRef<AddEditFormRef>(null);
  const { isLoading, serialGID, order, orderProgram, actionNames } = useOrderForm({ orderFormType });
  const { loading, returnOrRejectProgramOrderUpdateRequest, rejectReturnModalProps, setRejectReturnModalProps } =
    useDeanOfScientificResearchActions({
      orderId: order?.id ?? 0,
      serialGuid: serialGID ?? "",
    });

  const onSubmit = async (isDraft = false) => {
    if (formRef.current) {
      formRef.current.submitForm(isDraft, orderFormType);
    }
  };

  const onSaveAsDraft = async () => {
    onSubmit(true);
  };

  const programDetails = orderProgram?.programDetails.find(
    (item) => i18n.language === item.locale?.toLocaleLowerCase(),
  );

  const isReturnedOrder = orderFormType === OrderFormTypes.ReturnedOrder;
  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: isReturnedOrder ? "tasks" : "allOrders",
        path: isReturnedOrder ? "/home/tasks" : "/home/orders",
        menuItemId: isReturnedOrder ? "tasks" : "allOrders",
      },
      {
        localizationKey: order?.id ? "editOrder" : "addOrder",
        path:
          OrderFormTypes.EditOrder === orderFormType
            ? `/home/orders/edit/${order?.id}`
            : isReturnedOrder
            ? `/home/orders/editOrderTask/${serialGID}`
            : "/home/orders/add",
        localizationParameters: { orderNumber: order?.serialNumber },
      },
    ]);
  }, [order]);

  return (
    <>
      <PageWithTabs
        loading={isLoading}
        title={
          <div className="d-flex gap-2">
            <span>{programDetails?.displayName ?? ""}</span>
            <IconButton
              icon="icon-view"
              size="lg"
              fitToIconWidth
              innerIconColor="primary"
              iconPosition="start"
              onClick={() => {
                if (!!orderProgram?.id) navigate(`/programDetails/${orderProgram.id}`);
              }}
            />
          </div>
        }
        subTitle={
          <span>
            {t("programNumber")}: {orderProgram?.serialNumber ?? ""} • {t("cycle")}:
            {` ${orderProgram?.programCycleId ?? ""}-${orderProgram?.programCycleYearId ?? ""}`}
          </span>
        }
        titleImgSrc={orderProgram?.adsImage}
        pageHeaders={[
          {
            title: t("startContract"),
            value: toSystemThemeDateFormat(orderProgram?.contractStartDate),
          },
          {
            title: t("fundingTime"),
            value: orderProgram?.fundingDurationInMonths ?? "" + " " + t("month"),
          },
        ]}
        actions={() => {
          return (
            <>
              {orderFormType !== OrderFormTypes.EditOrderByDeanOfScientificResearch && (
                <>
                  <button className="btn btn-primary p-10-32P" onClick={() => onSubmit(false)}>
                    {!isReturnedOrder ? t("submitOrder") : t("send")}
                  </button>
                  {!isReturnedOrder && (
                    <button type="button" className="btn border bg-white text-dark p-10-32P" onClick={onSaveAsDraft}>
                      {t("saveAsDraft")}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn border bg-white text-dark p-10-32P"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    {t("Common:cancel")}
                  </button>
                </>
              )}

              {
                // Dean of scientific research actions
                orderFormType === OrderFormTypes.EditOrderByDeanOfScientificResearch && (
                  <>
                    <>
                      {actionNames?.some((ele) => ele.toLowerCase().includes("approve")) && (
                        <button
                          className="btn btn-primary px-3"
                          onClick={() => {
                            onSubmit(false);
                          }}
                        >
                          {t("approval")}
                        </button>
                      )}

                      {actionNames?.some((ele) => ele.toLowerCase().includes("reject")) && (
                        <button
                          className="btn btn-outline-secondary px-3"
                          onClick={() =>
                            setRejectReturnModalProps({
                              isOpen: true,
                              isReturn: false,
                            })
                          }
                        >
                          {t("reject", {
                            placeholder: t("updateRequest"),
                          })}
                        </button>
                      )}
                      {actionNames?.some((ele) => ele.toLowerCase().includes("return")) && (
                        <button
                          type="button"
                          className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
                          onClick={() =>
                            setRejectReturnModalProps({
                              isOpen: true,
                              isReturn: true,
                            })
                          }
                        >
                          <i className="icon-turn-order" />
                          {t("returnOrder")}
                        </button>
                      )}
                    </>
                  </>
                )
              }
            </>
          );
        }}
        tapContent={
          <OrderFormDetails
            ref={formRef}
            program={orderProgram}
            order={order}
            orderFormType={orderFormType}
            serialGuid={serialGID}
          />
        }
      />
      <ReturnOrRejectUpdateOrderModal
        isOpen={rejectReturnModalProps.isOpen}
        isReturn={rejectReturnModalProps.isReturn}
        onClose={() => {
          setRejectReturnModalProps({ isOpen: false, isReturn: false });
        }}
        onSubmit={returnOrRejectProgramOrderUpdateRequest}
        isSubmitting={loading}
      />
    </>
  );
};

export default OrderForm;

import { Controller, useForm } from "react-hook-form";
import { CreateOrderRequest, OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import ApplicantSection from "./ApplicantSection";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import { useTranslation } from "react-i18next";
import FormInput from "components/blocks/FormInput";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { getCreateOrderSchema } from "../createOrder.schema";
import useMutation from "hooks/useMutation";
import { OrdersService } from "services/orders";
import React from "react";
import LookupDropDowns from "./LookupDropDowns";
import RulesAndConditionSection from "./RulesAndConditionSection";
import { ProgramByIDResponse, ProgramDetailsResult } from "services/programs/models/ProgramTypes";
import { useNavigate, useParams } from "react-router-dom";
import { OrderStatus } from "shared/lookups";
import FormViewComponent from "../../../../../components/formBuilder/FormViewComponent";
import { AddEditFormRef, OrderFormTypes } from "..";
import Recommendations from "./Recommendations";
import FileUploadWithPreview, { SelectedFilesType } from "components/blocks/FileUploadWithPreview";

const OrderFormDetails = React.forwardRef<
  AddEditFormRef,
  {
    program?: ProgramByIDResponse | null;
    order: OrderLocalItemResult | null;
    serialGuid?: string;
    orderFormType: keyof typeof OrderFormTypes;
  }
>((props, ref) => {
  const { t, i18n } = useTranslation(["Orders", "Common"]);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const [files, setFiles] = useState<SelectedFilesType[]>([]);
  const [formSchema, setFormSchema] = useState();
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    control,
    reset,
  } = useForm<CreateOrderRequest>({
    resolver: getCreateOrderSchema(t),
    defaultValues: {
      centreManagementId: 0,
      programId: props.program?.id ?? 0,
      isAcceptedConditionAndRules: false,
      isAcceptedCommitments: false,
      filesIds: [],
      formSubject: "",
      formValue: "",
      researchFieldId: 0,
      subspecialty: "",
      isFormValueReady: true,
      isDraft: false,
    },
  });

  const { mutateAsync: addOrderAsync } = useMutation({
    queryFn: async (values: CreateOrderRequest) => {
      return await OrdersService.createOrder(values);
    },
    notificationSuccessMessage: t("orderSubmittedSuccessfully", {
      programSerial: props.program?.serialNumber,
    }),
  });

  const { mutateAsync: updateOrderAsync } = useMutation({
    queryFn: async ({ id, values }: { id: number; values: CreateOrderRequest }) => {
      return await OrdersService.updateOrder(id, values);
    },
    notificationSuccessMessage: t("orderSubmittedSuccessfully", {
      programSerial: props.program?.serialNumber,
    }),
  });

  const onSubmit = async (values: CreateOrderRequest, isDraft: boolean) => {
    values.filesIds = files.map((f) => f.uuid);
    values.isDraft = isDraft;

    const response = props.order
      ? await updateOrderAsync({ id: props.order.id!, values })
      : await addOrderAsync(values);

    if (response && !response.hasError) {
      setFiles([]);
      formRef?.current?.classList.remove("was-validated");
      navigate(`/home/orders/researcherOrders/allUnsignedOrders`);
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm: (isDraft: boolean, orderFormType: keyof typeof OrderFormTypes) => {
      handleSubmit((values) => {
        if (orderFormType === OrderFormTypes.ReturnedOrder) {
          values.serialGuid = props.serialGuid;
          values.actionName = "Complete";
        }

        if (orderFormType === OrderFormTypes.EditOrderByDeanOfScientificResearch) {
          values.serialGuid = props.serialGuid;
          values.actionName = "Approve";
        }

        onSubmit(values, isDraft);
        formRef.current?.classList.add("was-validated");
      })();
    },
  }));

  useEffect(() => {
    if (props.order) {
      reset({
        ...props.order,
        filesIds: props.order.files.map((f) => f.id),
        isDraft: OrderStatus.Draft === props.order.orderStatusId,
        subspecialty: props.order.subspecialty ?? "Test",
        formValue: props.order.formValue ?? "{}",
        researchFieldId: props.order.researchFieldId ?? 0,
      });

      setFiles(
        props.order?.files.map((f) => ({
          uuid: f.id,
          fileName: f.name ?? "",
          id: f.id,
          size: f.size,
          file: new File([], f.name ?? ""),
        })) ?? [],
      );
    }

    if (props.program?.id) {
      setValue("programId", props.program?.id);
    }
  }, [props.order, props.program?.id]);

  useEffect(() => {
    const formSchema = JSON.parse(props.program?.formBuilderSchema ?? "{}") as any;
    setFormSchema(formSchema);
  }, [props.program?.formBuilderSchema]);

  return (
    <form ref={formRef} noValidate onSubmit={handleSubmit((values) => onSubmit(values, false))}>
      <ApplicantSection />
      {props.order && OrderFormTypes.ReturnedOrder === props.orderFormType && <Recommendations order={props.order} />}
      <Accordion>
        <AccordionItem initiallyOpen title={t("submissionForm")} elementId="submissionForm">
          <div className="d-flex flex-column gap-3 pb-2 border-bottom border-1">
            <FormInput
              label={t("formSubject")}
              labelClassName="fw-bold"
              placeholder={t("formSubject")}
              {...register("formSubject")}
              error={errors.formSubject?.message}
            />

            <div>
              <Controller
                name="formValue"
                control={control}
                render={({ field }) => (
                  <FormViewComponent
                    data={field.value ? JSON.parse(field.value) : {}}
                    formDefinition={formSchema}
                    onChange={(values) => {
                      setValue("isFormValueReady", values.isValid);
                      setValue("formValue", JSON.stringify(values.data));
                    }}
                  />
                )}
              />

              {errors.isFormValueReady?.message && (
                <span className="invalid-feedback my-2" style={{ display: "block" }}>
                  {errors.isFormValueReady?.message}
                </span>
              )}
            </div>
            <LookupDropDowns
              control={control}
              errors={errors}
              setValue={setValue}
              order={props.order}
              isInspectMode={props.order?.isInspectMode}
            />
            <FileUploadWithPreview
              files={files}
              setFiles={setFiles}
              formProps={{}}
              error={errors.filesIds?.message}
              setValue={setValue}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <RulesAndConditionSection
        register={register}
        errors={errors}
        programDetails={
          props.program?.programDetails.find(
            (item) => item.locale?.toLowerCase() === i18n.language,
          ) as ProgramDetailsResult
        }
      />
    </form>
  );
});

export default OrderFormDetails;

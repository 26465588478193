import Modal from "components/blocks/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  EvaluateProgramUpdateRef,
  ResponseTypesOfProgramOrderUpdate,
} from "../../Tabs/OrderInfoTab/components/Updates/useProgramOrderUpdateActions";
import { useNavigate } from "react-router-dom";
import FileUploadWithPreview, { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import EditExtendRequestForm from "./EditExtendRequestForm";
import useEditExtendRequestForm from "./EditExtendRequestForm/useEditRequestForm";

const ProgramOrderUpdateActionModal = ({
  isOpen,
  onClose,
  programUpdateHandlersRef,
  serialGuid,
  type,
  order,
  program,
  setTriggerOrderUpdate,
}: {
  isOpen: boolean;
  onClose: () => void;
  serialGuid: string;
  programUpdateHandlersRef: React.MutableRefObject<EvaluateProgramUpdateRef | null>;
  type: ResponseTypesOfProgramOrderUpdate | "";
  program: ProgramByIDResponse | null;
  order: OrderLocalItemResult | null;
  setTriggerOrderUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("Orders");
  const [note, setNote] = useState("");
  const [files, setFiles] = useState<SelectedFilesType[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onCancel = () => {
    onClose?.();
    setFiles([]);
    setNote("");
  };

  const onModelClose = (navigateTo?: string) => {
    onCancel();
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  const {
    formRef,
    handleSubmit,
    sendProgramOrderUpdateResponse,
    sendProgramOrderUpdateResponseLoading,
    setValue,
    errors,
    watch,
  } = useEditExtendRequestForm({ note, order, serialGuid, type, onModelClose, setTriggerOrderUpdate });

  const getMessage = (type: string, defaultMsg?: string) => {
    const typeMapping: { [key: string]: string } = {
      RejectProgramOrderUpdate: t("reject", { placeholder: t("request") }),
      RejectUpdateRequest: t("reject", { placeholder: t("updateRequest") }),
      RejectExtendRequest: t("reject", { placeholder: t("extendRequest") }),
      RejectApologiesRequestByDeanOfScientificResearch: t("reject", { placeholder: t("apologizeRequest") }),
      RejectApologiesRequest: t("reject", { placeholder: t("apologizeRequest") }),

      ApproveApologiesRequest: t("approve", { placeholder: t("apologizeRequest") }),
      ApproveApologiesRequestByDeanOfScientificResearch: t("approve", { placeholder: t("apologizeRequest") }),
      ApproveUpdateRequest: t("approve", { placeholder: t("request") }),
      ApproveExtendRequest: t("approve", { placeholder: t("extendRequest") }),
      EditExtendDurationRequest: t("editExtendDurationRequest"),
      ReturnExtendRequestByDeanOfScientificResearch: t("returnOrderToCenter"),
      ReturnApologiesRequestByDeanOfScientificResearch: t("returnOrderToCenter"),
      default: t("returnRequestToResearcher"),
    };

    return typeMapping[type] || (defaultMsg ?? typeMapping.default);
  };

  const isEditOrApproveExtendRequest = type === "EditExtendDurationRequest" || type === "ApproveExtendRequest";
  const handleClick = async () => {
    if (!note.length || isSubmitting || sendProgramOrderUpdateResponseLoading) return;

    if (!isEditOrApproveExtendRequest) {
      await onSubmit(note);
    } else {
      formRef.current?.classList.add("was-validated");
      await handleSubmit(sendProgramOrderUpdateResponse)();
    }
  };

  const onSubmit = async (notes: string) => {
    setIsSubmitting(true);
    const res = await programUpdateHandlersRef.current?.sendProgramOrderUpdateResponse({
      note: notes,
      serialGuid: serialGuid,
      response: type as ResponseTypesOfProgramOrderUpdate,
      fileIds: files.map((f) => f.uuid),
    });

    if (res && !res.hasError) {
      onModelClose();
    }

    setIsSubmitting(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={getMessage(type)}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
      bodyMargin="py-3"
      containerClassName="bg-white pb-2"
    >
      {isEditOrApproveExtendRequest && (
        <EditExtendRequestForm
          formRef={formRef}
          errors={errors}
          setValue={setValue}
          watch={watch}
          program={program}
          type={type}
        />
      )}

      {type === "ReturnProgramOrderUpdate" && <FileUploadWithPreview files={files} setFiles={setFiles} />}

      <label className="form-label mt-2 fw-bold">{t("notes")}</label>
      <textarea
        className="form-control "
        rows={5}
        placeholder={t("notes")}
        onChange={(e) => setNote(e.target.value)}
        style={{ resize: "none" }}
      />

      <div className="divider mt-3"></div>

      <div className="d-flex justify-content-center gap-2 w-100">
        <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
          {t("Common:cancel")}
        </button>
        <button
          type="button"
          className="btn btn-primary rounded-3 p-10-32P"
          disabled={!note.length || isSubmitting || sendProgramOrderUpdateResponseLoading}
          onClick={handleClick}
        >
          {isSubmitting ? <div className="custom-loader"></div> : getMessage(type, t("returnRequest"))}
        </button>
      </div>
    </Modal>
  );
};

export default ProgramOrderUpdateActionModal;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrderUserTypes } from "..";
import {
  EvaluateProgramUpdateRef,
  ResponseTypesOfProgramOrderUpdate,
} from "../Tabs/OrderInfoTab/components/Updates/useProgramOrderUpdateActions";
import { OpenFundingTaskResult } from "services/tasks/models/tasksTypes";
import ProgramOrderUpdateActionModal from "../OrderTaskActionModals/ProgramOrderUpdateActionModal";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";

const ResearcherRequestActions = ({
  programUpdateHandlersRef,
  serialGuid,
  userType,
  openedTask,
  order,
  program,
  setTriggerOrderUpdate,
}: {
  userType: keyof typeof OrderUserTypes;
  serialGuid: string;
  openedTask: OpenFundingTaskResult | null;
  programUpdateHandlersRef: React.MutableRefObject<EvaluateProgramUpdateRef | null>;
  order: OrderLocalItemResult | null;
  program: ProgramByIDResponse | null;
  setTriggerOrderUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const [programOrderUpdateModalProps, setProgramOrderUpdateModalProps] = useState<{
    isOpen: boolean;
    type: ResponseTypesOfProgramOrderUpdate | "";
  }>({
    isOpen: false,
    type: "",
  });

  const getUserTypeActions = () => {
    const userActions = {
      [OrderUserTypes.ResearcherSendApologizeResponseAfterReturnRequest]: {
        send: "ResearcherSendApologiesAfterReturn",
      },
      [OrderUserTypes.ResearcherSendUpdateResponseAfterReturnRequest]: {
        send: "ResearcherSendProgramOrderUpdateAfterReturn",
      },
      [OrderUserTypes.ResearchCenterManagerForOrderUpdate]: {
        approveCallback: async () => {
          const res = await programUpdateHandlersRef.current?.acceptProgramOrderUpdate(serialGuid);
          if (res && !res.hasError) {
            navigate("/home/tasks");
          }
        },
        reject: "RejectProgramOrderUpdate",
      },
      [OrderUserTypes.ResearchCenterManagerForResearcherUpdateRequest]: {
        approve: "ApproveUpdateRequest",
        reject: "RejectUpdateRequest",
        return: "ReturnUpdateRequest",
      },
      [OrderUserTypes.ResearchCenterManagerForResearcherApologiesRequest]: {
        approve: "ApproveApologiesRequest",
        reject: "RejectApologiesRequest",
        return: "ReturnApologiesRequest",
      },

      [OrderUserTypes.ResearchCenterManagerForResearcherExtendRequest]: {
        approveCallback: async () => {
          setProgramOrderUpdateModalProps({ isOpen: true, type: "ApproveExtendRequest" });
        },
        reject: "RejectExtendRequest",
        return: "ReturnExtendRequest",
        edit: "EditExtendDurationRequest",
      },

      [OrderUserTypes.DeanOfScientificResearchForResearcherExtendRequest]: {
        approveCallback: async () => {
          setProgramOrderUpdateModalProps({ isOpen: true, type: "ApproveExtendRequest" });
        },
        reject: "RejectExtendRequest",
        return: "ReturnExtendRequestByDeanOfScientificResearch",
        edit: "EditExtendDurationRequest",
      },

      [OrderUserTypes.ResearcherEditProgramOrderTask]: {
        sendCallback: async () => {
          const res = await programUpdateHandlersRef.current?.editProgramOrderUpdate();
          // if (res && !res.hasError) navigate("/home/tasks"); TODO: check it
        },
      },

      [OrderUserTypes.DeanOfScientificResearchForResearcherApologiesRequest]: {
        approve: "ApproveApologiesRequestByDeanOfScientificResearch",
        reject: "RejectApologiesRequestByDeanOfScientificResearch",
        return: "ReturnApologiesRequest",
      },

      [OrderUserTypes.ResearcherSendExtendResponseAfterReturnRequest]: {
        send: "ResearcherSendExtendAfterReturn",
      },

      [OrderUserTypes.ResearchCenterManagerQueryRequest]: {
        send: "CenterManagerSendQueryResponse",
      },

      [OrderUserTypes.ResearcherSendQueryResponse]: {
        send: "ResearcherSendQueryResponse",
        sendTitle: t("noted"),
      },
    } as {
      [key: string]: {
        approve?: string;
        reject?: string;
        return?: string;
        send?: string;
        edit?: string;
        sendTitle?: string;
        approveCallback?: () => Promise<void>;
        sendCallback?: () => Promise<void>;
      };
    };

    return userActions[userType];
  };

  const userActions = getUserTypeActions();
  const onApproveProgramOrderUpdateAction = async () => {
    let res: any;
    if (userActions.approveCallback) {
      res = await userActions.approveCallback();
    } else {
      res = await programUpdateHandlersRef.current?.sendProgramOrderUpdateResponse({
        serialGuid,
        response: userActions.approve! as any,
        note: "",
        fileIds: [],
      });
    }

    if (res && !res.hasError) {
      navigate("/home/tasks");
    }
  };

  const onRejectProgramOrderUpdateAction = () => {
    setProgramOrderUpdateModalProps({ isOpen: true, type: userActions.reject! as ResponseTypesOfProgramOrderUpdate });
  };

  const onReturnProgramOrderUpdateAction = () => {
    setProgramOrderUpdateModalProps({ isOpen: true, type: userActions.return! as ResponseTypesOfProgramOrderUpdate });
  };

  const onSendProgramOrderUpdateResponse = async () => {
    let res: any;
    const userActions = getUserTypeActions();

    if (userActions.sendCallback) {
      res = await userActions.sendCallback();
    } else {
      res = await programUpdateHandlersRef.current?.sendProgramOrderUpdateResponse({
        serialGuid,
        response: userActions.send! as ResponseTypesOfProgramOrderUpdate,
        note: "",
        fileIds: [],
      });
    }

    if (res && !res.hasError) navigate("/home/tasks");
  };

  const getRejectMessage = () => {
    if (userType === OrderUserTypes.ResearchCenterManagerForResearcherUpdateRequest) {
      return t("reject", { placeholder: t("updateRequest") });
    } else if (userType === OrderUserTypes.ResearchCenterManagerForResearcherApologiesRequest) {
      return t("reject", { placeholder: t("apologizeRequest") });
    } else if (
      userType === OrderUserTypes.ResearchCenterManagerForResearcherExtendRequest ||
      userType === OrderUserTypes.DeanOfScientificResearchForResearcherExtendRequest
    ) {
      return t("reject", { placeholder: t("extendRequest") });
    } else if (userType === OrderUserTypes.ResearcherSendApologizeResponseAfterReturnRequest) {
      return t("reject", { placeholder: t("apologizeRequest") });
    } else {
      return t("reject", { placeholder: t("request") });
    }
  };

  return (
    <>
      {userActions && (
        <>
          {openedTask?.actionName.some((ele) => ele.toLowerCase().includes("approve")) && (
            <button className="btn btn-primary px-3" onClick={onApproveProgramOrderUpdateAction}>
              {t("approval")}
            </button>
          )}
          {userActions.edit && (
            <button
              className="btn btn-outline-secondary px-3"
              onClick={() => {
                setProgramOrderUpdateModalProps({ isOpen: true, type: "EditExtendDurationRequest" });
              }}
            >
              {t("editExtendDurationRequest")}
            </button>
          )}
          {openedTask?.actionName.some((ele) => ele.toLowerCase().includes("reject")) && (
            <button className="btn btn-outline-secondary px-3" onClick={onRejectProgramOrderUpdateAction}>
              {getRejectMessage()}
            </button>
          )}
          {openedTask?.actionName.some((ele) => ele.toLowerCase().includes("return")) && (
            <button
              type="button"
              className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
              onClick={onReturnProgramOrderUpdateAction}
            >
              {t("returnOrder")}
            </button>
          )}
        </>
      )}
      {userActions && (userActions.send || userActions.sendCallback) && (
        <>
          {(openedTask?.actionName.includes("Complete") || openedTask?.actionName.includes("Noted")) && (
            <button className="btn btn-primary px-3" onClick={onSendProgramOrderUpdateResponse}>
              {userActions.sendTitle ?? t("send")}
            </button>
          )}

          <button
            type="button"
            className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
            onClick={() => navigate("/home/tasks")}
          >
            {t("Common:cancel")}
          </button>
        </>
      )}
      <ProgramOrderUpdateActionModal
        isOpen={programOrderUpdateModalProps.isOpen}
        type={programOrderUpdateModalProps.type}
        onClose={() => setProgramOrderUpdateModalProps({ isOpen: false, type: "" })}
        serialGuid={serialGuid}
        programUpdateHandlersRef={programUpdateHandlersRef}
        order={order}
        program={program}
        setTriggerOrderUpdate={setTriggerOrderUpdate}
      />
    </>
  );
};

export default ResearcherRequestActions;

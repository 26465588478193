import PageWithTabs from "components/PageWithTabs";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useQuery } from "hooks/useQuery";
import { ExaminersService } from "services/examiners";
import ExaminerTaskContent from "./ExaminerTaskContent";
import { useEffect, useRef, useState } from "react";
import AcceptButton from "./ActionButtons/AcceptButton";
import ApologizeButton from "./ActionButtons/ApologizeButton";
import { ExaminerEvaluationFormRef } from "./ExaminerTaskContent/ExaminerEvaluationForm";
import ReturnButton from "./ActionButtons/ReturnButton";
import { useCookieContext } from "contexts";
import { TasksService } from "services/tasks";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";

const ExaminerTaskView = () => {
  // Hooks
  const { setBreadcrumbs } = useBreadcrumb();
  const navigate = useNavigate();
  const { t } = useTranslation("ExaminerTask");
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { serialGID } = useParams();
  const { isAr } = useCookieContext();

  //Open Task Api
  const { data: openedTask, loading: isTaskLoading } = useQuery({
    queryFn: async (_: string) => {
      const res = await TasksService.openFundingOrderTask(serialGID ?? "");
      return {
        data: res,
        hasError: false,
        code: 200,
        description: "",
      };
    },
    options: {
      enabled: !!serialGID,
    },
    triggers: [serialGID],
  });

  const evaluationFormRef = useRef<ExaminerEvaluationFormRef>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { data, loading, setData } = useQuery({
    queryFn: async () => {
      return await ExaminersService.getExaminerOrderEvaluationForm(
        Number(openedTask?.orderId),
        Number(openedTask?.examinerId),
      );
    },
    options: {
      enabled: !!openedTask?.orderId && !!openedTask?.examinerId,
    },
    triggers: [isAr, openedTask?.orderId, openedTask?.examinerId],
    onError: () => {
      navigate(-1);
    },
  });

  const onSubmitEvaluation = async () => {
    if (isFormSubmitting) {
      return;
    }

    setIsFormSubmitting(true);
    await evaluationFormRef.current?.submitForm();
    setIsFormSubmitting(false);
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "tasks",
        path: "/home/tasks",
        menuItemId: "tasks",
      },
      {
        localizationKey: "task",
        path: `/home/tasks/examiner/${serialGID}`,
        menuItemId: "tasks",
        localizationParameters: { taskNumber: data?.serialNumber ?? "" },
      },
    ]);
  }, [data]);

  return (
    <PageWithTabs
      title={
        <div className="d-flex gap-3">
          {data?.programDetail.displayName ?? ""}
          <Link to="" className="text-primary">
            <i className="icon-view" />
          </Link>
        </div>
      }
      loading={loading || isTaskLoading}
      subTitle={`${t("programNumber")}: ${data?.serialNumber ?? ""}`}
      pageHeaders={[
        {
          title: t("orderNumber"),
          value: data?.orderId ?? t("Common:noData"),
        },
        {
          title: t("researchCentre"),
          value: data?.centreManagement?.centreManagementDetail.displayName ?? t("Common:noData"),
        },
        {
          title: t("evaluationDeadLine"),
          value: data?.deadLine ? toSystemThemeDateFormat(data?.deadLine) : t("Common:noData"),
        },
      ]}
      actions={() => {
        return (
          <>
            {data && data.examinerStatus === "Accepted" && (
              <>
                {openedTask?.actionName.includes("Complete") && (
                  <button type="button" className="btn btn-primary px-4" onClick={() => onSubmitEvaluation()}>
                    {t("submitEvaluation")}
                  </button>
                )}
                {openedTask?.actionName.includes("Return") && (
                  <ReturnButton
                    t={t}
                    examinerProgramOrderId={openedTask?.examinerId ?? 0}
                    serialGuid={serialGID as string}
                    getRecommendations={evaluationFormRef.current?.getRecommendationsPropsOnReturn}
                  />
                )}
              </>
            )}
            {data && (
              <>
                {data.examinerStatus === "Pending" && (
                  <AcceptButton t={t} examinerProgramOrderId={openedTask?.examinerId ?? 0} setData={setData} />
                )}
                {openedTask?.actionName.includes("Apology") && (
                  <ApologizeButton
                    t={t}
                    serialGuid={serialGID as string}
                    examinerProgramOrderId={openedTask?.examinerId ?? 0}
                  />
                )}
              </>
            )}
          </>
        );
      }}
      tapContent={
        <ExaminerTaskContent
          task={data}
          ref={evaluationFormRef}
          serialGuid={serialGID as string}
          openedTask={openedTask}
        />
      }
    />
  );
};

export default ExaminerTaskView;
